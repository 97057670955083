<template>
  <div>
    <v-divider
      v-if="!mainButton.btnNewItem"
      class="mt-4"
    ></v-divider>
    <v-row
      class="mt-4"
      align="center"
      justify="center"
    >
      <!-- Save, Reorder, New Item -->
      <v-col
        :cols="5"
        md="3"
        sm="5"
        xs="5"
        class="text-center"
      >
        <v-btn
          block
          tile
          outlined
          class="ml-2 me-2 mt-2"
          color="primary"
          :disabled="mainButton.btnSaveDisabled"
          @click="$emit('save')"
        >
          <div v-if="!mainButton.btnReorder && !mainButton.btnNewItem">
            <v-icon left>mdi-pencil</v-icon>
            <!-- Save -->
            {{ $t("buttom.Save") }}
          </div>
          <div v-if="mainButton.btnReorder">
            <v-icon left>mdi-arrow-all</v-icon>
            <!-- Reorder -->
            {{ $t("buttom.Reorder") }}
          </div>
          <div v-if="mainButton.btnNewItem">
            <v-icon left>mdi-plus</v-icon>
            <!-- New -->
            {{ $t("buttom.New Item") }}
          </div>
        </v-btn>
      </v-col>

      <!-- cancel -->
      <v-slide-x-reverse-transition>
        <v-tooltip
          v-if="mainButton.btnCancelShow"
          right
        >
          <template v-slot:activator="{ on }">
            <v-btn
              icon
              class="ml-3"
              color="primary"
              @click="$emit('cancel')"
              v-on="on"
            >
              <v-icon>clear</v-icon>
            </v-btn>
          </template>
          <!-- cancel -->
          <span>{{ $t("buttom.Cancel") }}</span>
        </v-tooltip>
      </v-slide-x-reverse-transition>
    </v-row>

    <v-row
      no-gutters
      align="center"
      justify="center"
    >
      <v-col
        :cols="5"
        md="3"
        sm="5"
        xs="5"
        class="text-center"
      >
        <v-progress-linear
          v-if="mainButton.btnSaveDisabled"
          indeterminate
          class="ml-2 me-2 mt-1"
          color="primary"
        ></v-progress-linear>
      </v-col>
    </v-row>
    <br>
  </div>
</template>

<script>
export default {
  name: "MainButton",
  props: {
    mainButton: {},
  },
  methods: {},
};
</script>
