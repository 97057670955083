import { VApp } from 'vuetify/lib/components/VApp';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBottomNavigation } from 'vuetify/lib/components/VBottomNavigation';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMain } from 'vuetify/lib/components/VMain';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,{staticClass:"v-app-bg"},[_c(VMain,[_c(VContainer,{attrs:{"fluid":""}},[_c('router-view')],1)],1),(_vm.$store.state.login && _vm.$store.state.user.profile != 'Self-Service')?_c(VFooter,{attrs:{"padless":""}},[_c(VRow,{attrs:{"justify":"center","no-gutters":""}},[_c(VBottomNavigation,{staticClass:"blue-grey lighten-5 text-center blue--text"},[_vm._l((_vm.footer),function(item,i){return _c(VBtn,{key:i,staticClass:"mt-3"},[_c('router-link',{attrs:{"to":{ name: item.route }}},[_c(VIcon,{attrs:{"size":"26px"}},[_vm._v(_vm._s(item.icon))])],1),(item.text != '...')?_c('span',[_vm._v(_vm._s(_vm.$t("menu." + item.text)))]):_vm._e(),(item.text == '...')?_c('span',[_vm._v(" ... ")]):_vm._e()],1)}),_c(VMenu,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VBtn,_vm._g({staticClass:"mt-1",attrs:{"icon":""}},on),[_c(VAvatar,{attrs:{"color":"blue","size":"34"}},[_c('span',{staticClass:"white--text headline"},[_vm._v(_vm._s(_vm.$store.state.user.userName.substring(0, 1)))])])],1)]}}],null,false,1895932692)},[_c(VList,[_c(VSubheader,[_vm._v(_vm._s(_vm.$store.state.user.userName))]),_c(VListItemGroup,{attrs:{"color":"primary"}},_vm._l((_vm.footerUser),function(item,i){return _c(VListItem,{key:i,attrs:{"router":"","to":{
                   name: item.route,
                   params: {
                     userId: _vm.$store.state.user.userId,
                     pageId: 'footer',
                     option: item.option,
                   },
                 }}},[_c(VListItemIcon,[_c(VIcon,{domProps:{"textContent":_vm._s(item.icon)}})],1),_c(VListItemContent,[_c(VListItemTitle,{domProps:{"textContent":_vm._s(_vm.$t('menu.' + item.text))}})],1)],1)}),1)],1)],1)],2)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }