import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router/router.js'
import store from './store/store.js'
import vuetify from './plugins/vuetify'
import i18n from './i18n'

// component
import LoadingPage from '@/app/2_common/components/page/LoadingPage.vue'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
//
import FlagIcon from "vue-flag-icon"; // used in i18n
Vue.use(FlagIcon);

Vue.config.productionTip = false;

Vue.component('LoadingPage', LoadingPage)

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: function (h) { return h(App) }
}).$mount('#app')
