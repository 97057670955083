import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VRow,{attrs:{"justify":"center"}},[_c('div',{staticClass:"v-menu__content--active",staticStyle:{"display":"none","z-index":"1000"}},[_c(VDialog,{attrs:{"persistent":"","max-width":"480"},model:{value:(_vm.dialog.show),callback:function ($$v) {_vm.$set(_vm.dialog, "show", $$v)},expression:"dialog.show"}},[_c(VCard,[_c(VCardTitle,{staticClass:"headline"},[(!_vm.dialog.proceed)?_c('div',[_vm._v(" "+_vm._s(_vm.$t("dialog." + _vm.dialog.title))+" ")]):_vm._e(),(_vm.dialog.proceed)?_c('div',[_vm._v(" "+_vm._s(_vm.dialog.title)+" ")]):_vm._e()]),_c(VCardText,{staticClass:"subtitle-1 font-weight-black blue--text"},[_vm._v(_vm._s(_vm.dialog.text))]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.dialog.show = false;
              _vm.$emit('cancel');}}},[_vm._v(_vm._s(_vm.$t("buttom.Cancel")))]),(!_vm.dialog.proceed)?_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.$emit('confirm')}}},[_vm._v(_vm._s(_vm.$t("buttom.Confirm")))]):_vm._e(),(_vm.dialog.proceed)?_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.$emit('proceed')}}},[_vm._v(_vm._s(_vm.$t("buttom.Proceed")))]):_vm._e()],1)],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }