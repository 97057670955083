import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  // strict: true,  --> commented on "Error: [vuex] do not mutate vuex store state outside mutation handlers."
  state: {
    // login
    login: false,
    newAccount: false,
    //
    user: {
      userId: '',
    },
    enterprises: [],
    users: [],
    //////////

    // COMMON
    parameter: {},
    // module: '',

    // COMPANY
    enterpriseCounter: 0,
    sellerCounter: -1,
    // person
    entityForeigner: false,
    personType: '',
    addressState: '',
    reduced: '',
    // access
    view: false,
    edit: false,

    ////////
    // STOCK
    viewProductSupplier: false,
    viewServiceSupplier: false,
    editProductPriceSale: false,
    editServicePriceSale: false,
    serviceCounter: -1,

    // COMMERCIAL

  },

  mutations: {
    ////////////////////////////////////////////////////
    /// ACCESS
    ////////////////////////////////////////////////////
    UPDATE_LOGIN(state, payload) {
      state.login = payload
      // localStorage.section = state.user.token
      sessionStorage.section = state.user.token

    },
    UPDATE_NEW_ACCOUNT(state, payload) {
      state.newAccount = payload
    },
    //
    UPDATE_USER(state, payload) {
      state.user = Object.assign(state.user, payload)
    },
    UPDATE_ENTERPRISES(state, payload) {
      state.enterprises = payload
    },
    UPDATE_USERS(state, payload) {
      state.users = payload
    },
    //
    UPDATE_ENTERPRISE_COUNTER(state, payload) {
      state.enterpriseCounter = payload
    },

    ////////////////////////////////////////////////////
    /// COMMON
    ////////////////////////////////////////////////////
    UPDATE_PARAMETER(state, payload) {
      state.parameter = payload
    },
    // UPDATE_MODULE(state, payload) {
    //   state.module = payload
    // },

    ////////////////////////////////////////////////////
    /// COMPANY
    ////////////////////////////////////////////////////
    UPDATE_ENTITY_FOREIGNER(state, payload) {
      state.entityForeigner = payload
    },
    UPDATE_PERSON_TYPE(state, payload) {
      state.personType = payload
    },
    UPDATE_ADDRESS_STATE(state, payload) {
      state.addressState = payload
    },
    UPDATE_ACCESS_VIEW(state, payload) {
      state.view = payload
    },
    UPDATE_ACCESS_EDIT(state, payload) {
      state.edit = payload
    },
    UPDATE_SELLER_COUNTER(state, payload) {
      state.sellerCounter = payload
    },
    UPDATE_CUSTOMER_REDUCED_REGISTRATION(state, payload) {
      state.reduced = payload
    },

    ////////////////////////////////////////////////////
    /// STOCK
    ////////////////////////////////////////////////////
    UPDATE_ACCESS_VIEW_PRODUCT_SUPPLIER(state, payload) {
      state.viewProductSupplier = payload
    },
    UPDATE_ACCESS_VIEW_SERVICE_SUPPLIER(state, payload) {
      state.viewServiceSupplier = payload
    },
    UPDATE_ACCESS_EDIT_PRODUCT_PRICE_SALE(state, payload) {
      state.editProductPriceSale = payload
    },
    UPDATE_ACCESS_EDIT_SERVICE_PRICE_SALE(state, payload) {
      state.editServicePriceSale = payload
    },
    UPDATE_SERVICE_COUNTER(state, payload) {
      state.serviceCounter = payload
    },

  },
  actions: {}
})
