<template>
  <v-app class="v-app-bg">
    <!-- ///////////////////////////////////////////////////////////////////////////////////////////////////////////// -->
    <!-- v-content -->
    <v-main>
      <v-container fluid>
        <router-view />
      </v-container>
    </v-main>
    <!-- ///////////////////////////////////////////////////////////////////////////////////////////////////////////// -->
    <!-- v-footer -->

    <!-- $store.state.user.profile = {{ $store.state.user.profile }} -->
    <!-- v-if="$store.state.user.entity != 'supplier' && $store.state.user.entity != 'customer' && $store.state.user.profile != 'Self-Service' && $store.state.user.profile != 'Deliver'" -->

    <v-footer
      padless
      v-if="$store.state.login && $store.state.user.profile != 'Self-Service'"
    >
      <v-row
        justify="center"
        no-gutters
      >
        <v-bottom-navigation class="blue-grey lighten-5 text-center blue--text">
          <v-btn
            v-for="(item, i) in footer"
            :key="i"
            class="mt-3"
          >
            <router-link :to="{ name: item.route }">
              <v-icon size="26px">{{ item.icon }}</v-icon>
            </router-link>
            <!-- {{ item.text }} -->
            <span v-if="item.text != '...'">{{ $t("menu." + item.text) }}</span>
            <span v-if="item.text == '...'"> ... </span>
          </v-btn>
          <!-- ///////////////////////////////////////////////////////////////////////////////////////////////////////////// -->
          <!-- Menu User -->
          <v-menu bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                v-on="on"
                class="mt-1"
              >
                <v-avatar
                  color="blue"
                  size="34"
                >
                  <span class="white--text headline">{{
                    $store.state.user.userName.substring(0, 1)
                  }}</span>
                </v-avatar>
              </v-btn>
            </template>

            <v-list>
              <v-subheader>{{ $store.state.user.userName }}</v-subheader>
              <v-list-item-group color="primary">
                <v-list-item
                  v-for="(item, i) in footerUser"
                  :key="i"
                  router
                  :to="{
                    name: item.route,
                    params: {
                      userId: $store.state.user.userId,
                      pageId: 'footer',
                      option: item.option,
                    },
                  }"
                >
                  <v-list-item-icon>
                    <v-icon v-text="item.icon"></v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <!-- <v-list-item-title v-text="item.text"></v-list-item-title> -->
                    <v-list-item-title v-text="$t('menu.' + item.text)"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
        </v-bottom-navigation>
      </v-row>
      <!-- user supplier or Self-Service  -->
      <!-- <v-row
        v-else
        justify="center"
        no-gutters
      >
        <div v-if="$store.state.user.profile != 'Self-Service'">
          <v-bottom-navigation class="blue-grey lighten-5 text-center blue--text">
            <v-btn
              v-for="(item, i) in footer2"
              :key="i"
              class="mt-3"
            >
              <router-link :to="{ name: item.route }">
                <v-icon size="26px">{{ item.icon }}</v-icon>
              </router-link>
              <span v-if="item.text != '...'">{{ $t("menu." + item.text) }}</span>
              <span v-if="item.text == '...'"> ... </span>
            </v-btn>
            <v-menu bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-if="$store.state.user.profile != 'Self-Service'"
                  icon
                  v-on="on"
                  class="mt-1"
                >
                  <v-avatar
                    color="blue"
                    size="34"
                  >
                    <span class="white--text headline">{{
                    $store.state.user.userName.substring(0, 1)
                  }}</span>
                  </v-avatar>
                </v-btn>
              </template>

              <v-list>
                <v-subheader>{{ $store.state.user.userName }}</v-subheader>
                <v-list-item-group color="primary">
                  <v-list-item
                    v-for="(item, i) in footerUser2"
                    :key="i"
                    router
                    :to="{
                    name: item.route,
                    params: {
                      userId: $store.state.user.userId,
                      pageId: 'footer',
                      option: item.option,
                    },
                  }"
                  >
                    <v-list-item-icon>
                      <v-icon v-text="item.icon"></v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title v-text="$t('menu.' + item.text)"></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>
          </v-bottom-navigation>
        </div>
      </v-row>
 -->
    </v-footer>
    <!-- ///////////////////////////////////////////////////////////////////////////////////////////////////////////// -->
  </v-app>
</template>
<script>
export default {
  name: "App",
  components: {},
  data() {
    return {
      footer: [
        { text: "Menu", icon: "mdi-home-outline", route: "menu" },
        { text: "...", icon: "mdi-home-import-outline", route: "MenuModule" },
        { text: "Login", icon: "lock_open", route: "login" },
      ],
      // user supplier, Deliver
      // footer2: [
      //   { text: "Menu", icon: "mdi-home-outline", route: "menu" },
      //   { text: "Login", icon: "lock_open", route: "login" },
      // ],
      //
      footerUser: [
        {
          text: "My Account",
          icon: "account_circle",
          route: "userEdit",
          option: "myAccount",
        },
        {
          text: "Change Password",
          icon: "vpn_key",
          route: "userChangePassword",
          option: "",
        },
        {
          text: "Logout",
          icon: "logout",
          route: "login",
          option: "logout",
        },
      ],
      // user supplier
      // footerUser2: [
      //   {
      //     text: "Change Password",
      //     icon: "vpn_key",
      //     route: "userChangePassword",
      //     option: "",
      //   },
      //   {
      //     text: "Logout",
      //     icon: "logout",
      //     route: "login",
      //     option: "logout",
      //   },
      // ],
    };
  },
  //////////
  methods: {
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///  JS     /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /// clear
    logout() {},
  },
  ///////////
  created() {
    // console.log("1 .user.profile = " + this.$store.state.user.profile);
    // if (this.$store.state.user.profile == "Deliver") {
    //   this.footerUser2.unshift({
    //     text: "My Account",
    //     icon: "account_circle",
    //     route: "userEdit",
    //     option: "myAccount",
    //   });
    // }
  },

  ////////////
  mounted() {
    // console.log("2 .user.profile = " + this.$store.state.user.profile);
    // if (this.$store.state.user.profile == "Deliver") {
    //   this.footer = this.footer.shift();
    // }
  },
};
</script>

<style lang="scss">
@import "@/css/scss/style.scss";
</style>
