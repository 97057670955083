import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('nav',[_c('Navbar',{attrs:{"navbar":_vm.navbar},on:{"help":_vm.help}})],1),_c('main',{class:{
      'ml-12': _vm.$vuetify.breakpoint.mdAndUp,
      'ml-2': _vm.$vuetify.breakpoint.smAndDown,
      'me-12': _vm.$vuetify.breakpoint.mdAndUp,
      'me-2': _vm.$vuetify.breakpoint.smAndDown,
    }},[_c(VContainer,{attrs:{"fluid":""}},[_c(VCard,{staticClass:"mx-auto mt-4",attrs:{"flat":"","max-width":"600"}},[_c(VRow,{staticClass:"ml-8 mt-4 mb-0"},[_c(VCol,{attrs:{"cols":"2"}}),_c(VCol,{attrs:{"cols":"6"}},[_c(VRow,[_c(VImg,{attrs:{"src":require("@/assets/logo/logo_blue21.png"),"contain":"","max-width":"240","max-height":"60"}})],1)],1),_c(VCol,{attrs:{"cols":"4"}})],1),_c(VRow,[_c(VCol,{attrs:{"cols":"7"}},[_c(VRow,[_c('h1',{staticClass:"headline titleH1 ml-4 mt-4"},[_vm._v(_vm._s(_vm.$t("menu.Main Menu")))]),_c(VBtn,{attrs:{"icon":"","color":"primary"},on:{"click":function($event){_vm.titleHelp = !_vm.titleHelp}}},[_c(VIcon,{staticClass:"mb-0",attrs:{"size":"16px"}},[_vm._v("mdi-help-circle-outline")])],1)],1)],1),(_vm.$store.state.user.profile != 'Deliver' && _vm.$store.state.user.profile != 'Self-Service')?_c(VCol,{attrs:{"cols":"5"}},[(_vm.logoUrl)?_c(VImg,{ref:"imgLogoUrl",staticClass:"mt-4 me-4",attrs:{"id":"\n            imgLogoUrl","name":"imgLogoUrl","src":_vm.logoUrl,"max-width":"110","max-height":"110"},on:{"load":function($event){return _vm.getImgSize()}}}):_vm._e()],1):_vm._e()],1),_c(VAlert,{staticClass:"mt-3",attrs:{"type":"info","border":"left","text":"","transition":"slide-y-reverse-transition","dismissible":""},model:{value:(_vm.titleHelp),callback:function ($$v) {_vm.titleHelp=$$v},expression:"titleHelp"}},[_vm._v(_vm._s(_vm.$t("menu.titleHelp")))]),(_vm.$store.state.user.profile != 'Deliver' && _vm.$store.state.user.profile != 'Self-Service')?_c(VCard,{attrs:{"flat":""}},[_c(VRow,[_c(VCol,{attrs:{"cols":"7"}},[_c(VNavigationDrawer,{attrs:{"permanent":"","width":"100%"}},[_c(VList,{attrs:{"nav":""}},_vm._l((_vm.menuMain),function(item){return _c(VListItem,{key:item.module,attrs:{"link":""},on:{"click":function($event){return _vm.$router.push({
                    name: 'MenuModule',
                    params: {
                      module: item.route,
                    },
                  })}}},[_c(VListItemIcon,[_c(VIcon,{attrs:{"size":"28px"}},[_vm._v(_vm._s(item.icon))])],1),_c(VListItemContent,[_c(VListItemTitle,{staticClass:"text-h6"},[_vm._v(_vm._s(_vm.$t("common." + item.module)))])],1)],1)}),1)],1)],1),(_vm.establishment)?_c(VCol,{attrs:{"cols":"5"}},[_c(VImg,{staticClass:"mt-12",attrs:{"src":_vm.getImgUrl(),"height":"300","width":"200","contain":""}}),_c('span',{staticClass:"ml-4 mt-0 text-center text-h5 font-weight-bold"},[_vm._v(_vm._s(_vm.$t("enterprise." + _vm.establishmentName)))])],1):_vm._e()],1)],1):_vm._e(),(_vm.$store.state.user.profile != 'Deliver' && _vm.$store.state.user.profile != 'Self-Service')?_c(VRow,[_c(VBtn,{staticClass:"ml-6 mt-2",attrs:{"text":"","small":""},on:{"click":function($event){return _vm.goUrlHelp()}}},[_c(VIcon,{attrs:{"size":"22px","left":""}},[_vm._v("mdi-help-circle-outline")]),_vm._v("        "+_vm._s(_vm.$t("help.Help Site"))+" ")],1)],1):_vm._e(),(_vm.userName)?_c('h2',{staticClass:"ml-4 mt-16 text-h5 primary--text"},[_vm._v(" "+_vm._s(_vm.$t("user.Hello "))+" "+_vm._s(_vm.userName)+" !!! ")]):_vm._e(),_c(VSnackbar,{attrs:{"top":"top","color":_vm.snackbar.color,"timeout":_vm.snackbar.timeout},model:{value:(_vm.snackbar.show),callback:function ($$v) {_vm.$set(_vm.snackbar, "show", $$v)},expression:"snackbar.show"}},[_vm._v(_vm._s(_vm.snackbar.text))])],1),_c('br')],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }